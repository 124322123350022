<template>
  <div>
    <div v-if="screenContent.length === 0">
      <div v-for="(i) in 7" :key="i">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div>
    <div class="grid-view" v-else>
      <noorPlayGrid
        :contents="screenContent"
        :section="screen"
        :playerInstance="playerInstance"
        :message="errorMessage"
        :isScrollable="isScrollable"
        @loadMore="loadMoreContents()"
      ></noorPlayGrid>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { metaData } from "../../utilities";
export default {
  props: {
    menuDetail: {
      type: Object,
    },
    section: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
    routeObject : {
      type: Object
    }
  },
  data() {
    return {
      loader: true,
      screenContent: [],
      errorMessage: null,
      localDisplayLang: null,
      page: 1,
      isScrollable: false,
      isMpegRequired: false,
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    $route() {
      this.screenContent = [];
      this.page = 1;
      this.getContentScreen();
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.screenContent = [];
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mal' : this.localDisplayLang;
    //checking for change of language in local storage value
    eventBus.$on("check-for-series-route", (data) => {
      this.localDisplayLang = data;
      this.loader = true;
      this.screenContent = [];
      this.isScrollable = false;
      this.page = 1;
      this.getContentScreen();
    });

    this.getContentScreen();
  },
  mounted() {
    eventBus.$on("get-seeAll-data", (response) => {
      if (!response.reason) {
        if (this.screenContent && this.screenContent.length > 0) {
          this.screenContent = [...this.screenContent, ...response.data];

        } else {
          this.screenContent = [...response.data];
        }
        //Set isScrollable flag to true.
        this.isScrollable = response.totalcount === this.screenContent.length ? false : true;
        this.loader = false;
      } else {
        this.loader = false;
        if (response.errorcode != 6066 && response.errorcode != 1001) this.errorMessage = response.reason;
      }
    });
    this.actMetaData();
  },
  methods: {
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getContentScreen() {
        localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
        
        if(this.routeObject && typeof this.routeObject === "object") {
          this.screen = this.routeObject;
          this.getAllContents(this.isMpegRequired);
        } else {
          if (this.$router.currentRoute.params) {
            if (!this.$router.currentRoute.params.routeObject) {
              let menuId = this.$router.currentRoute.params.section;
    
              let index = this.appConfig.screens.findIndex((element) => {
                return (element.id).toLowerCase() === menuId.toLowerCase();
              });
    
              if (index > -1) {
                this.screen = this.appConfig.screens[index].sections[0];
                //get all detail component contents.
                this.getAllContents(this.isMpegRequired);
              } else {
                let screenData = this.appConfig.screens[0].sections.filter((item) => {
                  let itemTitle = item.title.default.replace(/[\s&/'/']+/g, "_")
                  itemTitle = itemTitle.toLowerCase();
                  if (itemTitle.includes(menuId)) {
                    return item;
                  }
                });
                this.screen = screenData[0];
                this.getAllContents(this.isMpegRequired);
              }
            } else if (this.$router.currentRoute.params.routeObject) {
              this.screen = this.$router.currentRoute.params.routeObject;
              this.getAllContents(this.isMpegRequired);
            }
          } else {
            this.screen = this.section;
            //get all detail component contents.
            this.getAllContents(this.isMpegRequired);
          }
        }
  
      },
    getEndPoint () {
      const sectionType = this.screen.sectionType;
      const itemType = this.screen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      }  else {
         return "/subscriber/v1/content"
      }
    },

    getAllContents(isMpegRequired) {
        if (!this.screen) {
          this.screen = localStorage.getItem("screen-list-collection") ? JSON.parse(localStorage.getItem("screen-list-collection")) : {};
        }
        let payload = {
          endpoint: this.screen.endpoint || this.getEndPoint(),
          params: this.screen.parameters || this.screen.sectionData || {}
        };
  
        if (this.screen.sectionType == "ITEMLIST") {
             let item = this.screen.sectionData.map((data) => {
              return data.id;
             })
            //  strItem = strItem.substring(0, strItem.length - 1);
             if (this.screen.itemType == "CONTENT") {
              payload.params = {
                contentlist : encodeURI(JSON.stringify((item))),
                orderby: {"objectid": item}
              }
             } else {
              payload.params = {
                contentlist : JSON.stringify(item)
              }
             }
           
          }
  
        payload.params.displaylanguage = this.localDisplayLang;
        payload.params.page = this.page;
  
        if (isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }
  
        let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");
  
        if (subscriberProfileDetails) {
          subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
        }
  
        if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
          payload.params.genre = "Kids";
        }
        setTimeout(() => {
          eventBus.$emit("grid-see-all-data", payload);
        }, 500)
        
      },
    initialRoute() {
      this.$router.push({ name: "Home" });
    },
    loadMoreContents() {
      this.page = this.page + 1;
      this.getAllContents(this.isMpegRequired);
    },
    actMetaData() {
      let sectionTitle = this.$router.currentRoute.params.section;
      let metaTempData = metaData() && metaData()[sectionTitle];
      var allMetaElements = document.getElementsByTagName('meta');
      let metaTitle = metaTempData ? metaTempData.title : "Saina Play - Watch Malayalam Movies Online, WebSeries & More";
      let metaDiscription = metaTempData ? metaTempData.description : "Saina Play is your one-stop destination for non-stop authentic 100% Malayalam entertainment. Watch latest & new Malayalam movies online anytime, anywhere.";
      let metaKeywords = metaTempData ? metaTempData.keywords : "Saina Play, Malayalam OTT platform, old & new movies, hollywood dubbed movies, music videos, kids shows, web series,"
      document.title= metaTitle;
      // if() 
      for(var metaValue = 0; metaValue<allMetaElements.length; metaValue ++) {
      if (allMetaElements[metaValue].getAttribute("name") == "description") { 
        allMetaElements[metaValue].setAttribute('content', metaDiscription); 
      } 
  
      if(allMetaElements[metaValue].getAttribute("property") == "og:description") { 
        allMetaElements[metaValue].setAttribute('content', metaDiscription); 
      } 
      if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
        allMetaElements[metaValue].setAttribute('content', metaDiscription); 
      } 
      if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
      allMetaElements[metaValue].setAttribute('content', metaKeywords); 
      }
      if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
      allMetaElements[metaValue].setAttribute('content', metaTitle); 
      } 
      if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
      allMetaElements[metaValue].setAttribute('content', metaTitle); 
      }
      }
    },
  },
  components: {
    categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
  },
  beforeDestroy() {
      this.screenContent = [];
      eventBus.$off("check-for-series-route");
      eventBus.$off("get-seeAll-data");
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./screen.scss"
</style>
